.overlap-group1{
	text-align: center;
  margin-top: 20px;
}
.overlap-group1 img{
    height: 100px;
}
/* YourComponent.css */
.react-toastify-bottom-center {
  left: 50%;
  transform: translateX(-50%) !important;
}

.copyright {
  color: #011b34;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 40px;
  text-align: center;
  white-space: nowrap;
}
@media screen and (max-width: 900px){
  .div23 {
    background-color: #ffffff;
    border: 2px solid;
    border-color: #3db7e4;
    margin: 20px;
    padding: 20px;

  }

  .text1-wrapper {
    color: #3db7e4;
    font-size: 18px;
    font-weight: 700;;
    letter-spacing: 0;
    line-height: 43.2px;;
    white-space: nowrap;
  }

  .rectangle-2 {
    border-radius: 20px;
    height: 40px;
    display: flex;
    margin-bottom: 20px;
    width: 100%;
    /* max-width: 300px; */

  }

  .img {
    height: 15px;
    left: 15px;
    margin: 8px 20px 0px 10px;
  }

  .text1-wrapper-2 {
    color: #bdafaf;
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 0;
  }

  .line {
    height: 5px;
  }
  .wraprshead{
    display: flex;
    border-bottom: 2px solid #000;
  }
  .wraprs{
      display: flex;
    }

  .text1-wrapper-3 {
    color: #001a33;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0;
    width: 23%;
    margin-right: 10px;
    text-align: left !important;
  }
  .list-item{
    color: #001a33;
    line-height: 30px;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    width: 25%;
    text-align: left !important;
    margin: 5px;
  }
}
@media screen and (min-width: 900px){
  .div23 {
    background-color: #ffffff;
    border: 2px solid;
    border-color: #3db7e4;
    padding: 20px;
    width: 75%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
    max-width: 800px;
  }

  .text1-wrapper {
    color: #3db7e4;
    font-size: 18px;
    font-weight: 700;;
    letter-spacing: 0;
    line-height: 43.2px;;
    white-space: nowrap;
  }

  .rectangle-2 {
    border-radius: 20px;
    height: 40px;
    display: flex;
    margin-bottom: 20px;
    width: 100%;
    /* max-width: 300px; */

  }

  .img {
    height: 15px;
    left: 15px;
    margin: 8px 20px 0px 10px;
  }

  .text1-wrapper-2 {
    color: #bdafaf;
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 0;
  }

  .line {
    height: 5px;
  }
  .wraprshead{
    display: flex;
    border-bottom: 2px solid #000;
  }
  .wraprs{
      display: flex;
    }

  .text1-wrapper-3 {
    color: #001a33;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    width: 25%;
    text-align: left !important;
  }
  .list-item{
    color: #001a33;
    line-height: 30px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    width: 25%;
    text-align: left !important;
  }
}