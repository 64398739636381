.sidebar-buttons {
    position: fixed;
    Right: 80px;
    top: 33%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.side-button {
    background-color: #55acee;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s;
    width: 150px;
    text-align: center;
}

.side-button:hover {
    background-color: #0056b3;
}
@media (max-width: 768px) {
    .side-button {
        width: auto; 
        padding: 8px 12px; 
        font-size: 12px;
    }
    .sidebar-buttons {
        top: 15%;
        right: 10px;
    }
}

@media (min-width: 700px) {
    
    .sidebar-buttons {
        top: 19%; /* Adjust based on your needs */
        

        
    }
}

