.self-carepage{
 display: grid;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin-right: auto;
  margin-left: auto;
  font-size: 20px !important; 
}
.home-content{
  min-width: 350px !important;
  justify-content: center;
	align-items: center;
	text-align: center;
}
.home-content img{
  height: 100px;
}
.actionbtns{
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-right: auto;
  margin-left: auto;
}

.actionbtns .userActions{
  width: 100%;
  display: block;
  padding: 5px;
  outline: none;
  border: none;
  background-image: linear-gradient(to right, #081c34, #081c34, #081c34);
  box-shadow: 0px 4px 4px #00000040, 0px 4px 4px #00000040, 0px 4px 4px #00000040 !important;
  color: #fff !important;
  margin: 0.5rem 0;
  height: 40px;
  cursor: pointer;
  font-style: unset !important;
  letter-spacing: 0 !important;
  border-radius: 5px !important;
  font-size: 10px !important;
  font-weight: 600 !important;
}

.self-carepage .text-wrapper-2 {
  margin-top: 10px;
	line-height: 35px;
	white-space: nowrap;
  color: #3db7e4;
  font-size: 23px !important;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
}
.self-carepage .support-call {
  color: #001a33;
  font-size: 15px !important;
  font-weight: 500;
  letter-spacing: 1px;
  text-align: center;
}

.self-carepage .copyright {
  color: #011b34;
  font-size: 15px !important;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 68.4px;
  text-align: center;
  white-space: nowrap;
}
.self-carepage .usercardData{
  /* background: linear-gradient(180deg, rgb(61, 183, 228) 0%, rgb(0, 26, 51) 97.56%); */
  border: 3px solid #3db7e4;
  color: #011b34;
  border-radius: 8px;
  overflow: hidden;
  /* color: #fff; */
  text-align: left !important;
  padding: 14px;
  letter-spacing: 1.5px;
  margin-top: 20px;
}
.usercardData .homeHeadings{
  font-weight: 600;
}

.modal-container {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  width: 100vw;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  margin-top: 200px;
}

.modal-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  margin-top: -20px;
  position: relative;
}

.titlestyle{
  /* max-width: 400px; */
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-top: 30px !important;
  position: relative;
}

.red-close-icon {
  color: #ff0000;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal-content {
  margin-top: 10px;
}

.user-details {
  margin-bottom: 20px;
  text-align: left;
}

input[type="tel"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}
input[type="number"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.button {
  margin-top: 20px;
}

input[type="submit"] {
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 5px 20px;
  cursor: pointer;
  font-size: 14px;
}

input[type="submit"]:hover {
  background: #0056b3;
}

.selecttxt{
width: 100%;
padding: 10px;
border: 1px solid #ccc;
border-radius: 4px;
font-size: 16px;
}

.passvalidator{
  padding: 2px;
  font-size: 11px;
  color: #f00;
}