*{
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: "DM Sans", sans-serif;
	font-size: 14px;
	/* background-image: url("../assets/background-1920-1.png"); */
	background-size: cover;
	background-attachment: fixed;
}
/* login and registration pages */

.new-user {
    display: flex;
	justify-content: space-around;
}

.new-user p{
    display: block;
	text-decoration: none;
	color: #999;
	font-size: 0.9rem;
	cursor: pointer;
}
.error-message {
	color: red;
	font-size: 10px;
	margin-top: 5px;
  }
.exist-user p{
    display: block;
	text-decoration: none;
	color: #999;
	font-size: 0.9rem;
	cursor: pointer;
}

a:hover{
	color: #38d39f;
}
.input-label{
	display: flex;
	justify-content: space-between;
	align-items: left;
	font-weight: 600;
	color: #081c34;
}

.forgot-password {
    display: flex; 
    justify-content: space-between; 
    align-items: center;
}

.text-wrapper {
	color: #55acee;
	font-size: 11px !important;
	font-weight: 400;
	letter-spacing: 0;
	line-height: 25px;
	top: 0;
	white-space: nowrap;
}
.overlap-group{
	display: flex;
}
.overlap-group input{
	margin-right: 5px;
}

.self-care .support-call {
	color: #001a33;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 0;
	text-align: center;
  }
  
  .self-care .copyright {
	color: #011b34;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 0;
	text-align: center;
	white-space: nowrap;
  }
  .forgotpass{
	cursor: pointer;
	border-radius: 5px;
	font-weight: 700;
	color: #081c34 !important;
  }

  @media screen and (min-width: 900px){
	.auth-container {
		width: 100vw;
		height: 100vh;
		display: grid;
		grid-template-columns: 1fr 1fr !important;
		padding: 0 2rem;
	  }
	.passiniput{
		display: flex;
		margin-top: -10px;
	}
	.passiniput .showpassicon{
		position: absolute;
		margin-top: 22px;
		right: 60%;
		color: white;
	}
	
	.Loginpageimg{
		display: flex;
		justify-content: center;
		align-items: center;
		
	}
	.Loginpageimg img{
		/* height: 90%;
		max-height: 100%;
		max-width: 100%; */

		max-width: 70%;
		height: auto;
		cursor: col-resize; 
		transition: width 0.3s ease-in-out;
	}

	.login-content{
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
	}
	.logForm, .regForm{
		width: 80%;
	}
	
	.login-content img{
		height: 100px;
	}
	
	.login-content h2{
		margin: 15px 0;
		color: #333;
		text-transform: uppercase;
		font-size: 2.0rem;
	}
	.self-care .text-wrapper-2 {
		color: #3db7e4;
		font-size: 25px !important;
		font-weight: 700;
		letter-spacing: 0;
		text-align: center;
		padding-top: 15px;
	  }
	  .self-care .log-in {
		color: #001a33;
		font-size: 20px !important;
		font-weight: 800;
		letter-spacing: 1px;
		line-height: 30px;
		text-align: center;
		white-space: nowrap;
	  }
	  .rectangle{
		border: 2px solid #808080;
		border-radius: 10px;
		padding: 30px;
		margin-right: auto;
		margin-left: auto;
	}
	.Auth-btn{
		display: block;
		width: 100%;
		height: 45px;
		border-radius: 5px;
		outline: none;
		border: none;
		background-image: linear-gradient(to right, #55acee, #55acee, #55acee);
		color: #fff;
		margin: 1rem 0;
		cursor: pointer;
		transition: .5s;
		font-size: 12px !important;
		font-style: unset !important;
		font-weight: 700 !important;
		letter-spacing: 0 !important;
		line-height: 24px !important;
	}
	.formInfor{
		display: block;
		width: 100%;
		height: 45px;
		border-radius: 0px !important;
		outline: none;
		border: none;
		background-image: linear-gradient(to right, #081c34, #081c34, #081c34);
		color: #fff !important;
		margin: 1rem 0;
		cursor: pointer;
		font-size: 14px !important;
		font-style: unset !important;
		font-weight: 600 !important;
		letter-spacing: 0 !important;
		line-height: 24px !important;
	}
	.formInfor::placeholder {
		color: #fff !important; /* Set the placeholder text color to white */
		font-weight: 600 !important;
	  }
	.formInfor:focus {
		border: none !important;
	  }
	.Auth-btn:hover{
		background-position: right;
	}
	.newuser{
		border-radius: 5px !important;
		font-size: 12px !important;
		font-weight: 700 !important;
	}
}

@media screen and (max-width: 1100px) {
	.auth-container{
		grid-template-columns: 1fr;
	}
	.passiniput{
		display: flex;
		margin-top: -10px;
	}
	.passiniput .showpassicon{
		position: absolute;
		margin-top: 22px;
		right: 10%;
		color: white;
	}
	.Logoo{
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		max-width: 100%;
		max-height: 100%;

	}
	.login-content{
		justify-content: center;
		align-items: center;
		text-align: center;
	}
	.login-content img{
		height: 80px;
	}
	.logForm, .regForm{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 85%;
		max-width: 500px;
	}
	.Loginpageimg{
		display: none;
	}
}

  @media screen and (max-width: 900px){
	.login-content h2{
        font-size: 2.4rem;
        margin: 8px 0;
	}
	.self-care .text-wrapper-2 {
		color: #3db7e4;
		font-size: 20px !important;
		font-weight: 700;
		line-height: 30px;
		letter-spacing: 0;
		text-align: center;
		padding: 5px 0px 5px 0px;
	  }
	  .self-care .log-in {
		color: #001a33;
		font-size: 20px !important;
		font-weight: 700;
		letter-spacing: 1px;
		line-height: 20px;
		padding-bottom: 10px;
		text-align: center;
		white-space: nowrap;
	  }
	  .rectangle{
		border: 1.5px solid #808080;
		border-radius: 8px;
		padding: 20px;
	}
	.Auth-btn{
		display: block;
		width: 100%;
		height: 40px;
		border-radius: 5px;
		outline: none;
		border: none;
		background-color: #55acee !important;
		background-image: linear-gradient(to right, #55acee, #55acee, #55acee);
		color: #fff;
		margin: 1rem 0;
		cursor: pointer;
		transition: .5s;
		font-size: 14px !important;
		font-style: unset !important;
		font-weight: 400 !important;
		letter-spacing: 0 !important;
		line-height: 24px !important;
	}
	
	.formInfor{
		display: block;
		width: 100%;
		height: 40px;
		border-radius: 0px !important;
		outline: none;
		border: none;
		background-image: linear-gradient(to right, #081c34, #081c34, #081c34);

		color: #fff !important;
		margin: 1rem 0;
		cursor: pointer;
		font-size: 12px !important;
		font-style: unset !important;
		font-weight: 400 !important;
		letter-spacing: 0 !important;
		line-height: 24px !important;
	}
	.formInfor::placeholder {
		color: #fff !important; /* Set the placeholder text color to white */
	  }
	.formInfor:focus {
		border: none !important;
	}
	.Auth-btn:hover{
		background-position: right;
		font-weight: 400 !important;
	}
	.newuser{
		border-radius: 5px !important;
	}
}

.logout-button {
	position: fixed;
	width: fit-content;
	right: 10px;
	display: block;
	padding: 5px;
	outline: none;
	border: none;
	background-image: linear-gradient(to right, #081c34, #081c34, #081c34);
	box-shadow: 0px 4px 4px #00000040, 0px 4px 4px #00000040, 0px 4px 4px #00000040 !important;
	color: #fff !important;
	/* margin: 1rem 0; */
	cursor: pointer;
	font-style: unset !important;
	letter-spacing: 0 !important;
	border-radius: 5px !important;
	font-size: 14px !important;
	font-weight: 600 !important;
  }
  .pin-button{
	position: fixed;
	width: fit-content;
	right: 90px;
	display: block;
	padding: 5px;
	outline: none;
	border: none;
	background-image: linear-gradient(to right, #081c34, #081c34, #081c34);
	box-shadow: 0px 4px 4px #00000040, 0px 4px 4px #00000040, 0px 4px 4px #00000040 !important;
	color: #fff !important;
	/* margin: 1rem 0; */
	cursor: pointer;
	font-style: unset !important;
	letter-spacing: 0 !important;
	border-radius: 5px !important;
	font-size: 14px !important;
	font-weight: 600 !important;
  }

  .back-button {
	position: fixed;
	width: fit-content;
	right: 10px;
	display: block;
	padding: 5px;
	outline: none;
	border: none;
	background-image: linear-gradient(to right, #081c34, #081c34, #081c34);
	box-shadow: 0px 4px 4px #00000040, 0px 4px 4px #00000040, 0px 4px 4px #00000040 !important;
	color: #fff !important;
	/* margin: 1rem 0; */
	cursor: pointer;
	font-style: unset !important;
	letter-spacing: 0 !important;
	border-radius: 5px !important;
	font-size: 14px !important;
	font-weight: 600 !important;;
  }


  .viewbtn {
	position: fixed;
	width: fit-content;
	display: block;
	padding: 5px;
	outline: none;
	border: none;
	background-image: linear-gradient(to right, #081c34, #081c34, #081c34);
	box-shadow: 0px 4px 4px #00000040, 0px 4px 4px #00000040, 0px 4px 4px #00000040 !important;
	color: #fff !important;
	margin: 1rem 0;
	cursor: pointer;
	font-style: unset !important;
	letter-spacing: 0 !important;
	border-radius: 5px !important;
	font-size: 11px !important;
	font-weight: 600 !important;
  }
/* .langselect{
	float: right !important;
	padding: 5px;
	right: 0px;
	margin-top: 20px;
	background-color: #55acee;
	color: #fff;
	
} */
.langselect {
    margin-top: 20px; 
}
.logoutlang{
	margin-left: 5px;
}

.flex-container {
    display: flex;
    column-gap: 5px; 
    align-items: flex-start; 
}

.flex-container button {
    padding: 8px; 
    font-size: 14px; 
    background-color:rgb(17, 140, 197);
    color: #fff; 
    border: 1px solid #55acee; 
    border-radius: 12px; 
    cursor: pointer;
}

.flex-container button:active,
.flex-container button:focus {
    background-color: rgb(17, 140, 197); 
    outline: none; /* Remove outline on focus */
}
.flex-container button.active {
    background-color:  #081c34 ; /* Change the background color to grey for the active button */ 
	cursor: not-allowed;
	pointer-events: none !important;
	opacity: 0.6;
}


.texttovoiceimg{
	position: fixed;
	bottom: 20px;
	right: 20px;
}